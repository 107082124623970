export const DISCUSS_OPTIONS = [
    { id: 1, name: "Access Discussions" },
    { id: 2, name: "Accounting Discussions" },
    { id: 3, name: "Attorney Discussions" },
    { id: 4, name: "Closing Letter Discussions" },
    { id: 5, name: "General Discussions" },
    { id: 6, name: "Insurance Discussions" },
    { id: 7, name: "Landscaping Discussions" },
    { id: 8, name: "Leasing Discussions" },
    { id: 9, name: "Lien/Cancelation" },
    { id: 10, name: "MRF Discussions" },
    { id: 11, name: "Unit Discussions" },
    { id: 12, name: "Violation Discussions" },
    { id: 13, name: "Others" },
];

export const COPY_TO_OPTIONS = [
    { control: 'copyBoard', name: "Copy To Board" },
    { control: 'copyManager', name: "Copy To Manager" },
    { control: 'createTask', name: "Create Task" },
];