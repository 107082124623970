import { Component, computed, DestroyRef, inject, signal, ViewEncapsulation } from "@angular/core";
import { finalize } from "rxjs";
import { takeUntilDestroyed, toSignal } from "@angular/core/rxjs-interop";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { UserConfigStore } from "@app/core/user-config/+data-access";
import { COPY_TO_OPTIONS, DISCUSS_OPTIONS } from "@app/pages/contacts/shared/const/contact-record-add.const";
import { PropertyModel } from "@app/pages/property/+data-access";
import { ITaskUser, TaskService } from "@app/pages/task/+data-access";
import { UnitModel } from "@app/pages/units/+data-access";
import { CustomerService } from "@app/shared/services";
import { AssociationService } from "@app/shared/services/association.service";
import { AttachmentType } from "@app/pages/financials/shared/const/attachment-type.const";
import { HttpClient } from "@angular/common/http";
import { environment } from "@environments/environment";
import { LocalStorageApi } from "@app/shared/services/local-storage.service";
import { AuthUser } from "@app/core/auth/auth-user";

interface DropdownItem {
  id: string;
  name: string;
  label?: string;
  value?: string;
  icon?: string;
}

@Component({
  selector: "app-dialog-add-contact-record",
  templateUrl: "./dialog-add-contact-record.component.html",
  styleUrl: "./dialog-add-contact-record.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class DialogAddContactRecordComponent {
  uploadBy: string = '';
  discussOptions = DISCUSS_OPTIONS;
  copyToOptions = COPY_TO_OPTIONS;
  form: FormGroup = new FormGroup({
    propertyId: new FormControl('', Validators.required),
    contactTypeId: new FormControl(''),
    assignedUserId: new FormControl(''),
    date: new FormControl('', Validators.required),
    description: new FormControl('', Validators.required),
    text: new FormControl('', Validators.required),
    modelType: new FormControl(''),
    modelId: new FormControl('', Validators.required),
    status: new FormControl(''),
    copyBoard: new FormControl(false),
    copyManager: new FormControl(false),
    createTask: new FormControl(false),
  });

  protected propertyId = signal<string>("");
  protected units = signal<any[]>([]);
  protected loaders = {
    propertiesLoading: signal(false),
    unitsLoading: signal(false),
    assignToLoading: signal(false),
    typeLoading: signal(false),
    statusLoading: signal(false),
    discussionsLoading: signal(false),
  };
  protected opts = {
    properties: signal<DropdownItem[]>([]),
    units: signal<DropdownItem[]>([]),
    assignTo: signal<DropdownItem[]>([]),
    type: signal<DropdownItem[]>([]),
    status: signal<DropdownItem[]>([]),
    discussions: signal<DropdownItem[]>([]),
  } as const;
  protected attachments = signal<any[]>([]);
  protected modelId = signal<string>("");
  protected attachmentModel = signal<AttachmentType | string>(AttachmentType.CONTACT);
  protected modelConfig = computed(() => {
    return {
      propertyId: this.propertyId()!,
      modelId: this.modelId(),
      model: this.attachmentModel(),
    };
  });

  readonly data = inject<any>(MAT_DIALOG_DATA);
  readonly dialogRef = inject(MatDialogRef<DialogAddContactRecordComponent>);
  protected associationService = inject(AssociationService);
  protected customerService = inject(CustomerService);
  protected taskService = inject(TaskService);
  protected userConfig = inject(UserConfigStore);
  protected http = inject(HttpClient);
  protected storage = inject(LocalStorageApi);
  #destroyRef = inject(DestroyRef);

  constructor() {
    const globalAssociationId = toSignal(
      this.userConfig.selectedAssociationId$,
      { initialValue: "" }
    );
    this.propertyId.set(globalAssociationId() || "");
    this.getOptionsService();

    const recordId = this.data.id ? this.data.id : crypto.randomUUID();
    this.modelId.set(recordId);

    this.uploadBy = this.storage.getItem<AuthUser>('user')?.name ?? '';
  }

  getOptionsService() {
    const date = new Date();
    date.setDate(date.getDate() + 1)

    this.form.patchValue({
      propertyId: this.propertyId(),
      date: date,
      modelId: this.data.unit?.id,
      modelType: this.data.unit?.name,
    })

    this.form.get('propertyId')?.disable();
    this.form.get('modelId')?.disable();

    this.loaders.propertiesLoading.set(true);
    this.associationService.getAssociationList().pipe(
      finalize(() => this.loaders.propertiesLoading.set(false)),
      takeUntilDestroyed(this.#destroyRef),
    ).subscribe(resp => {
      const items: PropertyModel[] = resp?.properties || [];
      this.opts.properties.set(
        items.map(item => {
          return {
            id: item.id,
            name: item.name,
          };
        })
      );
    });

    this.loaders.unitsLoading.set(true);
    this.associationService
      .getUnitsByAssociationId(this.propertyId())
      .pipe(
        finalize(() => this.loaders.unitsLoading.set(false)),
        takeUntilDestroyed(this.#destroyRef),
      ).subscribe(resp => {
        const items: UnitModel[] = resp?.units || [];
        this.opts.units.set(items.map(({ id, name }) => ({ id, name })));
        this.units.set(resp?.units || []);
      });

    this.loaders.assignToLoading.set(true);
    this.taskService.getAssignedusers().pipe(
      finalize(() => this.loaders.assignToLoading.set(false)),
      takeUntilDestroyed(this.#destroyRef),
    ).subscribe(resp => {
      const items: ITaskUser[] = resp?.data || [];
      this.opts.assignTo.set(
        items.map(item => ({ id: item.id, name: item.name }))
      );
    });

    this.loaders.typeLoading.set(true);
    this.customerService.getContactTypes().pipe(
      finalize(() => this.loaders.typeLoading.set(false)),
      takeUntilDestroyed(this.#destroyRef),
    ).subscribe(resp => {
      const items: any[] = resp || [];
      this.opts.type.set(
        items.map(item => ({ id: item.id, name: item.name }))
      );
    });

    this.loaders.statusLoading.set(true);
    this.customerService.getContactStatuses().pipe(
      finalize(() => this.loaders.typeLoading.set(false)),
      takeUntilDestroyed(this.#destroyRef),
    ).subscribe(resp => {
      const items: any[] = resp || [];
      this.opts.status.set(
        items.map(item => ({ id: item.id, name: item.name }))
      );
      this.form.get('status')?.setValue(
        this.opts.status().find(t => t.name.toLowerCase() === 'new')?.id
      )
    });

    this.loaders.discussionsLoading.set(true);
    this.customerService.getContactDiscussions().pipe(
      finalize(() => this.loaders.discussionsLoading.set(false)),
      takeUntilDestroyed(this.#destroyRef),
    ).subscribe(resp => {
      const items: any[] = resp || [];
      this.opts.discussions.set(
        items.map(item => ({ id: item.id, name: item.name }))
      );
    });
  }

  deleteFile(id: number): void {
    type Resp = { message: string };
    this.http.delete<Resp>(`${environment.apiUrl}/attachments/${id}`, {
      withCredentials: true,
    })
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe({
        next: () => {
          this.attachments.update((file) => {
            return file.filter(t => t.id !== id);
          });
        },
        error: () => {
          // TODO: alert user
        },
      });
  }

  fieldError(name: keyof typeof this.form.controls) {
    return this.form.controls[name].invalid && this.form.controls[name].touched;
  }

  onSave() {
    this.form.markAllAsTouched();

    if (!this.form.valid) return;

    this.dialogRef.close({
      form: this.form.getRawValue(),
      files: this.attachments()
    })
  }
}
